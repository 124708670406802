import React, { CSSProperties } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import SiteDetailsView from "./SiteDetailsView";
import SitesListView from "./SitesListView";
import {
  RegisterNewSiteModal,
  EditSiteModal,
  RegisterNewDeviceModal,
  EditDeviceModal,
  RemoveDeviceModal,
} from "../DeviceManagerModals";
import Loader from "../../Loader";
import config from "../../../config";
import {
  API_ABORT_MESSAGE,
  fetchAssets,
  registerNewSite,
  editSite,
  registerNewDevice,
  editDevice,
  removeDevice,
} from "../../../lib/api";
import { createMmsString } from "../../../lib/devices/deviceUtils";
import { useAuth } from "../../../state/authState";
import { useUI } from "../../../state/uiState";

import { FixedDevice, NormalizedSite } from "../../../lib/devices/deviceTypes";

export type SiteDataState = {
  devices: { [deviceId: string]: FixedDevice };
  loading: boolean;
  sites: { [siteId: string]: NormalizedSite };
  sitesLookup: { [siteKey: string]: string };
};

const SiteManagerPage = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) => {
  const { getAccessTokenSilently } = useAuth0();
  const { currentTenant } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { addAlerts, currentOverlay, setOverlay } = useUI();
  const [dataState, setDataState] = React.useState({
    devices: {},
    loading: true,
    sites: {},
    sitesLookup: {},
  } as SiteDataState);

  const doSiteAction = async (
    site: NormalizedSite,
    action: "register" | "edit"
  ) => {
    setDataState((prevDataState) => ({ ...prevDataState, loading: true }));

    try {
      const abortController = new AbortController();
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.basePath,
        },
      });

      if (action === "register") {
        await registerNewSite({
          accessToken,
          currentTenant,
          signal: abortController.signal,
          site,
        });
      } else {
        await editSite({
          accessToken,
          currentTenant,
          signal: abortController.signal,
          site,
        });
      }
      setDataState((prevDataState) => ({
        ...prevDataState,
        loading: false,
        sites: {
          ...prevDataState.sites,
          [site.id]: {
            ...site,
            siteMetadata: JSON.stringify(site.siteMetadata),
          },
        },
      }));
      setOverlay("");
    } catch (error) {
      if (error === API_ABORT_MESSAGE) {
        console.warn(error);
      } else {
        console.error(error);
        addAlerts([
          {
            severity: "error",
            message: (error as any).toString(),
          },
        ]);
        setDataState((prevDataState) => ({
          ...prevDataState,
          loading: false,
        }));
      }
    }
  };

  const doDeviceAction = async (
    device: FixedDevice,
    action: "register" | "edit" | "remove"
  ) => {
    setDataState((prevDataState) => ({ ...prevDataState, loading: true }));

    try {
      const abortController = new AbortController();
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.basePath,
        },
      });

      if (action === "register") {
        await registerNewDevice({
          accessToken,
          currentTenant,
          device,
          signal: abortController.signal,
        });
      } else if (action === "edit") {
        await editDevice({
          accessToken,
          currentTenant,
          device,
          signal: abortController.signal,
        });
      } else {
        await removeDevice({
          accessToken,
          currentTenant,
          device,
          signal: abortController.signal,
        });
      }
      setDataState((prevDataState) => {
        const site = Object.values(prevDataState.sites).find(
          (site) => site.id === device.siteId
        ) as NormalizedSite;

        return {
          ...prevDataState,
          devices: {
            ...prevDataState.devices,
            [device.id]: {
              ...device,
              deviceMetadata:
                typeof device.deviceMetadata === "string"
                  ? device.deviceMetadata
                  : JSON.stringify(device.deviceMetadata),
              deviceSecrets:
                typeof device.deviceSecrets === "string"
                  ? device.deviceSecrets
                  : JSON.stringify(device.deviceSecrets),
            },
          },
          loading: false,
          sites: {
            ...prevDataState.sites,
            [site.id]: {
              ...site,
              deviceIds: [...(new Set([...site.deviceIds, device.id]) as any)],
            },
          },
        };
      });
      setOverlay("");
    } catch (error) {
      if (error === API_ABORT_MESSAGE) {
        console.warn(error);
      } else {
        console.error(error);
        addAlerts([
          {
            severity: "error",
            message: (error as any).toString(),
          },
        ]);
        setDataState((prevDataState) => ({
          ...prevDataState,
          loading: false,
        }));
      }
    }
  };

  const site = searchParams.get("site") ?? "";
  const currentSite = currentOverlay.includes("Site:")
    ? dataState.sites[currentOverlay.split(":")[1]]
    : dataState.sites[dataState.sitesLookup[site]];
  const sitesList = Object.values(dataState.sites);
  const siteNames = sitesList.map((site) => site.name);
  const intersectionIdsAndRegions = sitesList
    .filter((site) => site.intersectionId !== null)
    .map(
      (site) =>
        `${site.intersectionId}${
          typeof site.roadRegulatorId === "number"
            ? `:${site.roadRegulatorId}`
            : ""
        }`
    );

  const devicesList = Object.values(dataState.devices);
  const ipAddresses = devicesList
    .filter((device) => device.ipAddress && device.enrolled)
    .map((device) => device.ipAddress as string);
  const mmsDevices = devicesList.filter(
    (device) =>
      typeof device.make === "string" && typeof device.model === "string"
  );
  const mmsEnrolledDevices = mmsDevices.filter((device) => device.enrolled);
  const makeModelSerialNumbers = mmsDevices.map(createMmsString);
  const enrolledMakeModelSerialNumbers =
    mmsEnrolledDevices.map(createMmsString);

  const setSite = (newSite: string) => {
    setSearchParams((prevSearchParams) => {
      if (newSite === "") {
        prevSearchParams.delete("site");
      } else {
        prevSearchParams.set("site", newSite);
      }
      return prevSearchParams;
    });
  };

  React.useEffect(() => {
    const abortController = new AbortController();

    const loadDeviceManagerData = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.basePath,
        },
      });

      try {
        const devicesAndSites = await fetchAssets({
          accessToken,
          currentTenant,
          signal: abortController.signal,
        });
        setDataState({
          devices: devicesAndSites.devices,
          loading: false,
          sites: devicesAndSites.sites,
          sitesLookup: devicesAndSites.sitesLookup,
        });
      } catch (error) {
        if (error === API_ABORT_MESSAGE) {
          console.warn(error);
        } else {
          console.error(error);
        }
      }
    };

    loadDeviceManagerData();

    return () => {
      abortController.abort(API_ABORT_MESSAGE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, currentTenant]);

  if (dataState.loading && !Object.keys(dataState.sites).length) {
    return <Loader />;
  }
  const wrapperStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  };

  if (dataState.loading) {
    wrapperStyle.cursor = "wait";
  }

  const { style, ...rest } = props;

  return (
    <div className="app-page" style={{ ...wrapperStyle, ...style }} {...rest}>
      {currentOverlay === "registerNewSite" ? (
        <RegisterNewSiteModal
          confirmAction={async (site) => {
            await doSiteAction(site, "register");
            window.location.reload();
          }}
          intersectionIdsAndRegions={intersectionIdsAndRegions}
          siteNames={siteNames}
        />
      ) : null}
      {currentOverlay.startsWith(`editSite:`) ? (
        <EditSiteModal
          confirmAction={async (site) => {
            await doSiteAction(site, "edit");
          }}
          intersectionIdsAndRegions={intersectionIdsAndRegions}
          site={currentSite}
          siteNames={siteNames}
        />
      ) : null}
      {currentOverlay === "registerNewDevice" ? (
        <RegisterNewDeviceModal
          confirmAction={async (device) => {
            await doDeviceAction(device, "register");
            window.location.reload();
          }}
          enrolledMakeModelSerialNumbers={enrolledMakeModelSerialNumbers}
          ipAddresses={ipAddresses}
          makeModelSerialNumbers={makeModelSerialNumbers}
          site={currentSite}
        />
      ) : null}
      {currentOverlay.startsWith(`editDevice:`) ? (
        <EditDeviceModal
          confirmAction={async (device) => {
            await doDeviceAction(device, "edit");
          }}
          device={dataState.devices[currentOverlay.split(":")[1]]}
          enrolledMakeModelSerialNumbers={enrolledMakeModelSerialNumbers}
          ipAddresses={ipAddresses}
          makeModelSerialNumbers={makeModelSerialNumbers}
          site={currentSite}
        />
      ) : null}
      {currentOverlay.startsWith(`removeDevice:`) ? (
        <RemoveDeviceModal
          confirmAction={async (device) => {
            await doDeviceAction(device, "remove");
          }}
          device={dataState.devices[currentOverlay.split(":")[1]]}
        />
      ) : null}
      {site ? (
        <SiteDetailsView
          dataState={dataState}
          setDataState={setDataState}
          setSite={setSite}
        />
      ) : (
        <SitesListView dataState={dataState} setSite={setSite} />
      )}
    </div>
  );
};
export default SiteManagerPage;
