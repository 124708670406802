import React from "react";
import { MobileDevice, NormalizedVehicle } from "../../lib/devices/deviceTypes";
import { generateRandomString } from "../../lib/generate";
import { getMobileDeviceTypeLabel } from "../../lib/v2x/v2xUtils";
import Toggle from "../controls/Toggle";
import Modal from "../Modal";
import Button from "../controls/Button";
import Radio from "../controls/Radio";
import Input from "../controls/Input";
import Select from "../controls/Select";

const MobileDeviceModal = ({
    confirmAction,
    enrolledMakeModelSerialNumbers,
    headerText,
    makeModelSerialNumbers,
    device: prevDevice,
    vehicle,
}: {
    confirmAction: (device: MobileDevice) => void;
    enrolledMakeModelSerialNumbers: string[];
    headerText: string;
    makeModelSerialNumbers: string[];
    device?: MobileDevice;
    vehicle: NormalizedVehicle;
}) => {
    const prevDeviceType = prevDevice?.deviceType ?? "OBU";
    const prevSerialNumber = prevDevice?.serialNumber ?? "";
    const prevMake = prevDevice?.make ?? "";
    const prevModel = prevDevice?.model ?? "";
    const prevEnrolled = prevDevice?.enrolled ?? true;

    const prevMakeModelSerialNumber =
        typeof prevMake === "string" &&
            typeof prevModel === "string" &&
            typeof prevSerialNumber === "string"
            ? `${[prevDeviceType, prevMake, prevModel]
                .join(",")
                .toUpperCase()},${prevSerialNumber}`
            : null;

    const [nextDeviceType, setNextDeviceType] = React.useState(prevDeviceType);
    const [nextSerialNumber, setNextSerialNumber] =
        React.useState(prevSerialNumber);
    const [nextMake, setNextMake] = React.useState(prevMake);
    const [nextModel, setNextModel] = React.useState(prevModel);
    const [nextEnrolled, setNextEnrolled] = React.useState(prevEnrolled);

    const toughbookModels = [
        "CF-20-2",
        "CF-31-6",
        "CF-33-1",
        "CF-33-2",
        "CF33-3",
        "CF-54-2",
        "CF-54-3",
        "FZ40-1",
        "FZ55-1",
        "FZ55-2",
        "FZG1-5",
        "FZG2-1",
        "FZM1-3",
    ];

    const nextMakeModelSerialNumber =
        typeof nextMake === "string" &&
            typeof nextModel === "string" &&
            typeof nextSerialNumber === "string"
            ? `${[nextDeviceType, nextMake, nextModel]
                .join(",")
                .toUpperCase()},${nextSerialNumber}`
            : null;

    const serialNumberError =
        typeof nextMakeModelSerialNumber === "string" &&
            (!prevEnrolled ||
                prevMakeModelSerialNumber !== nextMakeModelSerialNumber) &&
            enrolledMakeModelSerialNumbers.includes(nextMakeModelSerialNumber)
            ? "Make + Model + Serial Number combination is already taken"
            : nextDeviceType === "TOUGHBOOK"
                ? !nextSerialNumber.trim().length
                    ? "*Serial Number is required"
                    : !/^[A-Z0-9]*$/.test(nextSerialNumber)
                        ? "TOUGHBOOK Serial Numbers must by upper case and only include alphanumeric characters"
                        : ""
                : "";

    const modelError =
        typeof nextModel === "string" && nextDeviceType === "TOUGHBOOK"
            ? !nextModel.trim().length
                ? "*TOUGHBOOK Model is required"
                : !toughbookModels.includes(nextModel)
                    ? "TOUGHBOOK Model is not a recognized model"
                    : ""
            : "";

    const allMakes = [
        ...(new Set(makeModelSerialNumbers.map((mms) => mms.split(",")[1])) as any),
    ].sort();
    let filteredMakes = allMakes.filter(
        (make) =>
            makeModelSerialNumbers.find((mms) =>
                mms.startsWith(`${nextDeviceType.toUpperCase()},${make},`)
            ) !== undefined
    );

    if (nextModel) {
        filteredMakes = filteredMakes.filter(
            (make) =>
                makeModelSerialNumbers.find((mms) =>
                    mms.startsWith(`${nextDeviceType.toUpperCase()},${make},${nextModel}`)
                ) !== undefined
        );
    }
    const allModels = [
        ...(new Set(makeModelSerialNumbers.map((mms) => mms.split(",")[2])) as any),
    ].sort();
    let filteredModels = allModels.filter((model) =>
        makeModelSerialNumbers.find(
            (mms) =>
                mms.startsWith(`${nextDeviceType.toUpperCase()},`) &&
                mms.includes(`,${model}`)
        )
    );

    if (nextMake) {
        filteredModels = filteredModels.filter(
            (model) =>
                makeModelSerialNumbers.find((mms) =>
                    mms.startsWith(
                        `${nextDeviceType.toUpperCase()},${nextMake.toUpperCase()},${model}`
                    )
                ) !== undefined
        );
    }
    const isEnrollable =
        prevEnrolled ||
        nextMakeModelSerialNumber === null ||
        !enrolledMakeModelSerialNumbers.includes(nextMakeModelSerialNumber);

    return (
        <Modal
            className="light"
            header={<h2>{headerText}</h2>}
            footer={
                <Button
                    className="action lg"
                    disabled={
                        !isEnrollable ||
                        !!serialNumberError ||
                        (!!modelError && nextDeviceType === "TOUGHBOOK") ||
                        (prevDeviceType === nextDeviceType &&
                            prevSerialNumber === nextSerialNumber &&
                            prevMake === nextMake &&
                            prevModel === nextModel &&
                            prevEnrolled === nextEnrolled)
                    }
                    onClick={() => {
                        const nextDevice: MobileDevice = {
                            changedAt: new Date().getTime(),
                            deviceClass: "MOBILE" as const,
                            deviceType: nextDeviceType,
                            enrolled: nextEnrolled,
                            id: prevDevice?.id ?? generateRandomString(),
                            make:
                                nextDeviceType === "TOUGHBOOK"
                                    ? "PANASONIC"
                                    : (nextMake as string),
                            model: nextModel as string,
                            serialNumber: nextSerialNumber as string,
                            vehicleId: vehicle.id,
                        };

                        confirmAction(nextDevice);
                    }}
                    style={{ marginTop: 32 }}
                >
                    CONFIRM
                </Button>
            }
        >
            <div
                style={{
                    height: 64,
                    marginTop: 6,
                    marginBottom: 24,
                    overflow: "visible",
                }}
            >
                <Radio
                    changed={
                        !headerText.includes("New") && prevDeviceType !== nextDeviceType
                    }
                    label="Device Type"
                    name="device-type"
                    onChange={(type) => setNextDeviceType(type as any)}
                    options={[
                        {
                            label: getMobileDeviceTypeLabel("OBU"),
                            value: "OBU",
                        },
                        {
                            label: getMobileDeviceTypeLabel("TOUGHBOOK"),
                            value: "TOUGHBOOK",
                        },
                    ]}
                    required
                    value={nextDeviceType}
                />
            </div>
            <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
                <Input
                    changed={
                        !headerText.includes("New") && prevSerialNumber !== nextSerialNumber
                    }
                    id="serial-number"
                    label="Serial Number"
                    autoComplete="off"
                    maxLength={200}
                    onChange={(e) => setNextSerialNumber(e.target.value.replace(",", ""))}
                    placeholder="e.g. ABC000001"
                    type="text"
                    validate={() => serialNumberError}
                    value={nextSerialNumber}
                />
            </div>
            {nextDeviceType !== "TOUGHBOOK" ? (
                <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
                    <Input
                        changed={!headerText.includes("New") && prevMake !== nextMake}
                        id="make"
                        label="Make"
                        autoComplete="off"
                        maxLength={200}
                        onChange={(e) =>
                            setNextMake(e.target.value.toUpperCase().replace(",", ""))
                        }
                        placeholder="e.g. ACME"
                        recommendations={filteredMakes}
                        type="text"
                        value={nextMake}
                        warning={
                            !!nextMake && !allMakes.includes(nextMake.toUpperCase())
                                ? "New Make"
                                : ""
                        }
                    />
                </div>
            ) : null}
            {nextDeviceType === "TOUGHBOOK" ? (
                <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
                    <Select
                        changed={!headerText.includes("New") && prevModel !== nextModel}
                        label="Model"
                        onChange={(e) => {
                            setNextModel(e.target.value);
                        }}
                        options={toughbookModels}
                        required
                        value={nextModel}
                        warning={modelError}
                    />
                </div>
            ) : (
                <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
                    <Input
                        changed={!headerText.includes("New") && prevModel !== nextModel}
                        id="model"
                        label="Model"
                        autoComplete="off"
                        maxLength={200}
                        onChange={(e) =>
                            setNextModel(e.target.value.toUpperCase().replace(",", ""))
                        }
                        placeholder="e.g. ABC-1234"
                        recommendations={filteredModels}
                        type="text"
                        value={nextModel}
                        warning={
                            allModels.includes(nextModel?.toUpperCase()) &&
                                !filteredModels.includes(nextModel?.toUpperCase())
                                ? "Model might belong to another Make"
                                : !!nextModel && !allModels.includes(nextModel.toUpperCase())
                                    ? "New Model"
                                    : ""
                        }
                    />
                </div>
            )}
            <div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 24,
                        marginBottom: 24,
                    }}
                >
                    <Toggle
                        disabled={!prevDevice}
                        changed={
                            !headerText.includes("New") && prevEnrolled !== nextEnrolled
                        }
                        checked={nextEnrolled}
                        id="enrolled"
                        onChange={() => setNextEnrolled(!nextEnrolled)}
                    />
                    <div style={{ marginLeft: 18 }}>
                        <label htmlFor="enrolled" style={{ cursor: "pointer" }}>
                            {nextEnrolled ? "" : "Not "}Enrolled
                        </label>
                        <p style={{ marginTop: 4 }}>
                            Enrolled Devices are Devices that Cirrus should track and
                            communicate with.{" "}
                            <b style={{ fontWeight: 750 }}>
                                Unenrolled Device configurations will be removed from view.
                            </b>
                        </p>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const RegisterNewMobileDeviceModal = ({
    confirmAction,
    enrolledMakeModelSerialNumbers,
    makeModelSerialNumbers,
    vehicle,
}: {
    confirmAction: (device: MobileDevice) => void;
    enrolledMakeModelSerialNumbers: string[];
    makeModelSerialNumbers: string[];
    vehicle: NormalizedVehicle;
}) => (
    <MobileDeviceModal
        confirmAction={confirmAction}
        enrolledMakeModelSerialNumbers={enrolledMakeModelSerialNumbers}
        headerText="Register New Device"
        makeModelSerialNumbers={makeModelSerialNumbers}
        vehicle={vehicle}
    />
);

export const EditMobileDeviceModal = ({
    confirmAction,
    device,
    enrolledMakeModelSerialNumbers,
    makeModelSerialNumbers,
    vehicle,
}: {
    confirmAction: (device: MobileDevice) => void;
    device: MobileDevice;
    enrolledMakeModelSerialNumbers: string[];
    makeModelSerialNumbers: string[];
    vehicle: NormalizedVehicle;
}) => (
    <MobileDeviceModal
        confirmAction={confirmAction}
        device={device}
        enrolledMakeModelSerialNumbers={enrolledMakeModelSerialNumbers}
        headerText="Edit Device"
        makeModelSerialNumbers={makeModelSerialNumbers}
        vehicle={vehicle}
    />
);

export const RemoveMobileDeviceModal = ({
    confirmAction,
    device,
}: {
    confirmAction: (device: MobileDevice) => void;
    device: MobileDevice;
}) => (
    <Modal
        className="light"
        footer={
            <Button className="action lg" onClick={() => confirmAction(device)}>
                CONFIRM
            </Button>
        }
        header={
            <h2 style={{ fontSize: "24px" }}>
                Are you sure you want to remove this Device?
            </h2>
        }
    >
        <p style={{ marginBottom: 42, fontSize: "16px", color: "#161616" }}>
            This action cannot be undone. Once a Device has been removed from a
            Vehicle, it will need to be registered again from the Vehicle Detail view.
        </p>
    </Modal>
);
