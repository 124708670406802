import React from "react";

import SiteRow from "./SiteRow";
import Button from "../../controls/Button";
import Input from "../../controls/Input";
import { useUI } from "../../../state/uiState";

import { SiteDataState } from "./SiteManagerView";

const editSiteWidth = 68;
const viewDetailWidth = 88;

const SitesListView = ({
  dataState,
  setSite,
}: {
  dataState: SiteDataState;
  setSite: (newSite: string) => void;
}) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const { setOverlay } = useUI();

  const sitesList = Object.values(dataState.sites);

  const searchResults = sitesList.filter(
    (site) =>
      site.intersectionId?.toString().includes(searchTerm.toLowerCase()) ||
      site.id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      site.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      site.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      site.lon.toString().includes(searchTerm) ||
      site.lat.toString().includes(searchTerm) ||
      site.deviceIds.find((deviceId) => {
        const device = dataState.devices[deviceId];

        return (
          device.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (device.ipAddress ?? "").includes(searchTerm.toLowerCase()) ||
          device.make?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          device.model?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          device.serialNumber?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          height: 40,
          marginBottom: 24,
        }}
      >
        <div style={{ display: "flex" }}>
          {sitesList.length ? (
            <Input
              autoFocus
              className={searchResults.length ? undefined : "error"}
              placeholder="Search by Site Name, Intersection ID, etc."
              type="search"
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
              style={{ width: searchTerm ? 283 : 323 }}
            />
          ) : null}
          <Button
            className="action lg"
            onClick={() => setOverlay("registerNewSite")}
            style={{ marginLeft: 16 }}
          >
            REGISTER NEW SITE
          </Button>
        </div>
      </div>
      {sitesList.length ? (
        <div
          style={{
            height: "calc(100% - 64px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 18,
              padding: "12px 24px",
              color: "#c6c6c6",
              fontSize: "14px",
              lineHeight: "16px",
            }}
          >
            <span style={{ flex: "0 1 150px", minWidth: 50 }}>Site Name</span>
            <span style={{ flex: "0 1 260px", minWidth: 50, maxWidth: 260 }}>
              Site ID (generated)
            </span>
            <span style={{ flex: "0 1 130px", minWidth: 130, maxWidth: 130 }}>
              Site Type
            </span>
            <span style={{ flex: "0 1 150px", minWidth: 50 }}>Latitude</span>
            <span style={{ flex: "0 1 150px", minWidth: 50 }}>Longitude</span>
            <span style={{ flex: "0 1 40px", minWidth: 40 }}>
              Enrolled Devices
            </span>
            <span
              style={{
                flex: `0 1 250px`,
                minWidth: 50,
              }}
            />
            <span
              style={{
                flex: `0 1 ${editSiteWidth + viewDetailWidth}px`,
                minWidth: editSiteWidth + viewDetailWidth,
                maxWidth: editSiteWidth + viewDetailWidth,
              }}
            />
          </div>
          <div style={{ overflowY: "scroll" }}>
            {searchResults.map((result) => (
              <SiteRow
                devices={dataState.devices}
                key={result.id}
                isLoading={dataState.loading}
                showViewDetail
                site={result}
                setSite={setSite}
              />
            ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <h2 style={{ fontSize: "34px" }}>No registered Sites</h2>
        </div>
      )}
    </>
  );
};
export default SitesListView;
