import React from "react";
import { Link } from "react-router-dom";

import Button from "../../controls/Button";
import { serviceBasePaths } from "../../../config";
import { canAccessService } from "../../../lib/auth";
import { useAuth } from "../../../state/authState";
import { useUI } from "../../../state/uiState";

import { FixedDevice, NormalizedSite } from "../../../lib/devices/deviceTypes";

const editSiteWidth = 68;
const viewDetailWidth = 88;

const SiteRow = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    devices: { [deviceId: string]: FixedDevice };
    isLoading: boolean;
    showViewDetail: boolean;
    site: NormalizedSite;
    setSite?: (newSite: string) => void;
  }
) => {
  const { currentUser, getDebug } = useAuth();
  const { setOverlay } = useUI();

  const { devices, isLoading, showViewDetail, site, setSite, ...rest } = props;

  return (
    <div
      style={{
        display: "flex",
        gap: 18,
        alignItems: "center",
        width: "100%",
        height: "75px",
        marginBottom: "12px",
        padding: "12px 16px 12px 24px",
        borderRadius: "8px",
        backgroundColor: "#1e1e1e",
        fontSize: "14px",
      }}
      {...rest}
    >
      <div
        style={{
          flex: "0 1 150px",
          minWidth: 50,
        }}
        title={site.name}
      >
        <span
          style={{
            maxHeight: "34px",
            overflow: "hidden",
            display: "-webkit-box",
          }}
        >
          {site.name}
        </span>
      </div>
      <div
        style={{
          flex: "0 1 260px",
          minWidth: 50,
          maxWidth: 260,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        title={site.id}
      >
        <span>{site.id}</span>
      </div>
      <div
        style={{
          flex: "0 1 130px",
          minWidth: 130,
          maxWidth: 130,
        }}
        title={
          site.intersectionId !== null && site.id !== "-"
            ? `Intersection ${site.intersectionId}${
                site.roadRegulatorId !== null ? `:${site.roadRegulatorId}` : ""
              }`
            : "Standalone"
        }
      >
        <span
          className="badge"
          style={{
            display: "inline-block",
          }}
        >
          {site.intersectionId !== null && site.id !== "-"
            ? `Intersection ${site.intersectionId}${
                site.roadRegulatorId !== null ? `:${site.roadRegulatorId}` : ""
              }`
            : "Standalone"}
        </span>
      </div>
      <div
        style={{
          flex: "0 1 150px",
          minWidth: 50,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        title={site.lat.toString()}
      >
        <span>{site.lat}</span>
      </div>
      <div
        style={{
          flex: "0 1 150px",
          minWidth: 50,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        title={site.lon.toString()}
      >
        <span>{site.lon}</span>
      </div>
      <div
        style={{ flex: "0 1 40px", minWidth: 40 }}
        title={site.deviceIds
          .filter((deviceId) => devices[deviceId].enrolled)
          .length.toString()}
      >
        <span>
          {
            site.deviceIds.filter((deviceId) => devices[deviceId].enrolled)
              .length
          }
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flex: "0 1 250px",
          justifyContent: "right",
          minWidth: 50,
        }}
      >
        {canAccessService(currentUser.authMetadata, "web_client:time_travel") &&
        getDebug() === "enabled" ? (
          <Link
            onClick={(e) => e.stopPropagation()}
            to={`${
              serviceBasePaths["web_client:time_travel"]
            }?source=${encodeURIComponent(site.id)}`}
            title="See in Time Travel"
          >
            See in Time Travel
          </Link>
        ) : null}
      </div>
      <div
        style={{
          flex: `0 1 ${
            showViewDetail ? editSiteWidth : editSiteWidth + viewDetailWidth
          }px`,
          display: "flex",
          justifyContent: "right",
          minWidth: showViewDetail
            ? editSiteWidth
            : editSiteWidth + viewDetailWidth,
          maxWidth: showViewDetail
            ? editSiteWidth
            : editSiteWidth + viewDetailWidth,
        }}
        title="Edit Site"
      >
        <Button
          className="inline"
          disabled={isLoading || site.name === "Unknown"}
          onClick={() => setOverlay(`editSite:${site.id}`)}
        >
          Edit Site
        </Button>
      </div>
      {showViewDetail ? (
        <div
          style={{
            flex: `0 1 ${viewDetailWidth}px`,
            display: "flex",
            justifyContent: "right",
            minWidth: viewDetailWidth,
            maxWidth: viewDetailWidth,
          }}
          title="View Detail"
        >
          <Link
            to={`${
              serviceBasePaths["web_client:device_management"]
            }?site=${encodeURIComponent(site.id)}`}
          >
            View Detail
          </Link>
        </div>
      ) : null}
    </div>
  );
};
export default SiteRow;
