import React, { CSSProperties } from "react";

import { useAuth } from "../../state/authState";
import { useSearchParams } from "react-router-dom";

import NotFoundPage from "./NotFoundPage";

import { UserAuthMetadata } from "../../lib/auth";
import TabPanel from "../controls/TabPanel";
import SiteManagerView from "../deviceManager/sites/SiteManagerView";
import VehicleManagerView from "../deviceManager/VehicleManagerView";

enum Views {
  sites = 0,
  vehicles = 1,
}

const wrapperStyle: CSSProperties = {
  paddingLeft: 16,
  paddingRight: 16,
};

const DeviceManagerPage = ({
  authorize,
}: {
  authorize: (uam: UserAuthMetadata) => boolean;
}) => {
  const { currentUser } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab =
    Views[(searchParams.get("view") as keyof typeof Views) ?? "sites"];
  const setActiveTab = (tab: number) => {
    const view = Views[tab];
    setSearchParams((prevSearchParams) => {
      prevSearchParams.set("view", view);
      return prevSearchParams;
    });
  };

  const site = searchParams.get("site");
  const vehicle = searchParams.get("vehicle");

  return authorize(currentUser.authMetadata) ? (
    <>
      {site && <SiteManagerView style={wrapperStyle} />}
      {vehicle && <VehicleManagerView style={wrapperStyle} />}
      {!site && !vehicle && (
        <TabPanel
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          style={wrapperStyle}
          tabs={[
            {
              title: "Sites",
              content: <SiteManagerView />,
            },
            {
              title: "Vehicles",
              content: <VehicleManagerView />,
            },
          ]}
        />
      )}
    </>
  ) : (
    <NotFoundPage />
  );
};
export default DeviceManagerPage;
