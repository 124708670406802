import React from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Button as MUIButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import RestoreIcon from "@mui/icons-material/Restore";
import { GridActionsCellItem } from "@mui/x-data-grid";

import SiteRow from "./SiteRow";
import Button from "../../controls/Button";
import InteractiveTable from "../../controls/InteractiveTable";
import Toggle from "../../controls/Toggle";
import config from "../../../config";
import {
  API_ABORT_MESSAGE,
  editDevice,
  fetchSiteDetails,
} from "../../../lib/api";
import { validateIpAddress } from "../../../lib/validation";
import { getDeviceTypeLabel } from "../../../lib/v2x/v2xUtils";
import { useAuth } from "../../../state/authState";
import { useUI } from "../../../state/uiState";

import ArrowBackIcon from "../../../assets/icons/arrowBack.svg";
import { FixedDevice } from "../../../lib/devices/deviceTypes";
import { SiteDataState } from "./SiteManagerView";
import Loader from "../../Loader";

const editSiteWidth = 68;
const viewDetailWidth = 88;

const SiteDetailsView = ({
  dataState,
  setDataState,
  setSite,
}: {
  dataState: SiteDataState;
  setDataState: React.Dispatch<React.SetStateAction<SiteDataState>>;
  setSite: (newSite: string) => void;
}) => {
  const { currentTenant, getDebug } = useAuth();
  const { getAccessTokenSilently } = useAuth0();
  const [searchParams] = useSearchParams();
  const [maps, setMaps] = React.useState(new Array<any>());
  const debugEnabled = getDebug() === "enabled";
  const [mapsLoading, setMapsLoading] = React.useState(debugEnabled);
  const { addAlerts, currentOverlay, setOverlay } = useUI();

  const siteParam = searchParams.get("site") ?? "";
  const currentSite = currentOverlay.includes("Site:")
    ? dataState.sites[currentOverlay.split(":")[1]]
    : dataState.sites[dataState.sitesLookup[siteParam]];
  const siteId = currentSite.id;

  const toggleNetworkServiceAccess = async (
    device: FixedDevice,
    service: "ping" | "snmp" | "ssh",
    enable: boolean
  ) => {
    const nextDevice = { ...device };
    const nextParsedMetadata = {
      managed_by: null,
      ping: false,
      snmp: false,
      ssh: false,
      ...(device.deviceMetadata ? JSON.parse(device.deviceMetadata) : {}),
    };
    const nextParsedSecrets = {
      snmp_auth_password: null,
      snmp_priv_password: null,
      snmp_port: null,
      snmp_username: null,
      ssh_password: null,
      ssh_username: null,
      ...(device.deviceSecrets ? JSON.parse(device.deviceSecrets) : {}),
    };

    if (service === "ping") {
      nextParsedMetadata.ping = enable;
    } else if (service === "snmp") {
      nextParsedMetadata.snmp = enable;
    } else {
      nextParsedMetadata.ssh = enable;
    }
    setDataState((prevDataState) => ({
      ...prevDataState,
      devices: {
        ...prevDataState.devices,
        [device.id]: {
          ...nextDevice,
          deviceMetadata: JSON.stringify(nextParsedMetadata),
          deviceSecrets: JSON.stringify(nextParsedSecrets),
        },
      },
      loading: true,
    }));

    try {
      const abortController = new AbortController();
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.basePath,
        },
      });

      await editDevice({
        accessToken,
        currentTenant,
        device: {
          ...nextDevice,
          deviceClass: "FIXED",
          deviceMetadata: nextParsedMetadata,
          deviceSecrets: nextParsedSecrets,
        },
        signal: abortController.signal,
      });
      setDataState((prevDataState) => ({ ...prevDataState, loading: false }));
    } catch (error) {
      if (error === API_ABORT_MESSAGE) {
        console.warn(error);
      } else {
        console.error(error);
        addAlerts([
          {
            severity: "error",
            message: (error as any).toString(),
          },
        ]);
        setDataState((prevDataState) => {
          const prevDevice = { ...device };
          const prevParsedMetadata = {
            managed_by: null,
            ping: false,
            snmp: false,
            ssh: false,
            ...(device.deviceMetadata ? JSON.parse(device.deviceMetadata) : {}),
          };
          const prevParsedSecrets = {
            snmp_auth_password: null,
            snmp_priv_password: null,
            snmp_port: null,
            snmp_username: null,
            ssh_password: null,
            ssh_username: null,
            ...(device.deviceSecrets ? JSON.parse(device.deviceSecrets) : {}),
          };

          if (service === "ping") {
            prevParsedMetadata.ping = !enable;
          } else if (service === "snmp") {
            prevParsedMetadata.snmp = !enable;
          } else {
            prevParsedMetadata.ssh = !enable;
          }
          return {
            ...prevDataState,
            devices: {
              ...prevDataState.devices,
              [device.id]: {
                ...prevDevice,
                deviceMetadata: JSON.stringify(prevParsedMetadata),
                deviceSecrets: JSON.stringify(prevParsedSecrets),
              },
            },
            loading: false,
          };
        });
      }
    }
  };

  React.useEffect(() => {
    if (!currentTenant || !debugEnabled) return;

    const abortController = new AbortController();

    const loadSiteDetails = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.services.restApi.basePath,
        },
      });

      try {
        const siteDetailsResponse = await fetchSiteDetails({
          accessToken,
          currentTenant,
          siteId,
          signal: abortController.signal,
        });

        setMaps(siteDetailsResponse.maps as any);
        setMapsLoading(false);
      } catch (error) {
        if (error === API_ABORT_MESSAGE) {
          console.warn(error);
        } else {
          console.error(error);
        }
      }
    };

    loadSiteDetails();

    return () => {
      abortController.abort(API_ABORT_MESSAGE);
    };
  }, [currentTenant, debugEnabled, getAccessTokenSilently, siteId]);

  return (
    <>
      <Button
        className="inline return"
        onClick={() => setSite("")}
        style={{
          display: "flex",
          alignItems: "center",
          width: 90,
          minHeight: 28,
          marginTop: 16,
          marginBottom: 32,
          marginLeft: -8,
          fontSize: "16px",
        }}
        title="Return to list"
      >
        <img src={ArrowBackIcon} alt="Return to list" width={16} height={17} />{" "}
        <span style={{ marginLeft: 8 }}>Return</span>
      </Button>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: 40,
          marginBottom: 24,
        }}
      >
        <h1>Site Detail</h1>
        {currentSite ? (
          <Button
            className="action lg"
            disabled={dataState.loading || currentSite.name === "Unknown"}
            onClick={() => setOverlay("registerNewDevice")}
          >
            REGISTER NEW DEVICE
          </Button>
        ) : null}
      </div>
      {currentSite ? (
        <>
          <div>
            <div
              style={{
                display: "flex",
                gap: 18,
                padding: "12px 24px",
                color: "#c6c6c6",
                fontSize: "14px",
                lineHeight: "16px",
              }}
            >
              <span style={{ flex: "0 1 150px", minWidth: 50 }}>Site Name</span>
              <span style={{ flex: "0 1 260px", minWidth: 50, maxWidth: 260 }}>
                Site ID (generated)
              </span>
              <span style={{ flex: "0 1 130px", minWidth: 130, maxWidth: 130 }}>
                Site Type
              </span>
              <span style={{ flex: "0 1 150px", minWidth: 50 }}>Latitude</span>
              <span style={{ flex: "0 1 150px", minWidth: 50 }}>Longitude</span>
              <span style={{ flex: "0 1 40px", minWidth: 40 }}>
                Enrolled Devices
              </span>
              <span
                style={{
                  flex: `0 1 250px`,
                  minWidth: 50,
                }}
              />
              <span
                style={{
                  flex: `0 1 ${editSiteWidth + viewDetailWidth}px`,
                  minWidth: editSiteWidth + viewDetailWidth,
                  maxWidth: editSiteWidth + viewDetailWidth,
                }}
              />
            </div>
            <SiteRow
              devices={dataState.devices}
              isLoading={dataState.loading}
              showViewDetail={false}
              site={currentSite}
            />
            {debugEnabled ? (
              <div style={{ marginTop: 44 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: 40,
                    marginBottom: 24,
                  }}
                >
                  <h1>MAP Messages</h1>
                  <MUIButton
                    color="primary"
                    disabled
                    href={`/map-manager?mode=edit&site=${encodeURIComponent(
                      currentSite.id
                    )}`}
                    size="large"
                    startIcon={<AddIcon />}
                    style={{
                      fontSize: "12px",
                      fontWeight: 900,
                      height: 40,
                      padding: "0 23px",
                    }}
                    variant="contained"
                  >
                    Draft new MAP
                  </MUIButton>
                </div>
                {mapsLoading ? (
                  <Loader />
                ) : (
                  <InteractiveTable
                    columns={[
                      {
                        field: "mapId",
                        flex: 2,
                        headerName: "MAP ID",
                      },
                      {
                        field: "intersectionId",
                        flex: 2,
                        headerName: "Intersection ID",
                      },
                      {
                        field: "roadRegulatorId",
                        flex: 2,
                        headerName: "Road Regulator ID",
                      },
                      {
                        field: "publishedAt",
                        flex: 2,
                        headerName: "Published At",
                      },
                      {
                        field: "publishedBy",
                        flex: 3,
                        headerName: "Published By",
                      },
                      {
                        field: "actions",
                        type: "actions",
                        width: 50,
                        getActions: () => [
                          (
                            <GridActionsCellItem
                              disabled
                              icon={<FileOpenIcon />}
                              key="openMAP"
                              label="Open MAP"
                              showInMenu
                            />
                          ) as any,
                          <GridActionsCellItem
                            disabled
                            icon={<LibraryAddIcon />}
                            key="draftMAPCopy"
                            label="Draft MAP Copy"
                            showInMenu
                          />,
                          <GridActionsCellItem
                            disabled
                            icon={<RestoreIcon />}
                            key="revertToMAP"
                            label="Revert to MAP"
                            showInMenu
                          />,
                        ],
                      },
                    ]}
                    rows={maps.map((m) => ({
                      id: m.mapId,
                      mapId: m.mapId,
                      intersectionId: m.mapMetadata.intersection_id,
                      roadRegulatorId: m.mapMetadata.road_regulator_id,
                      publishedAt: m.changedAt,
                      publishedBy: m.changedBy,
                    }))}
                  />
                )}
              </div>
            ) : null}
            {currentSite.deviceIds.length ? (
              <div>
                <h1
                  style={{
                    marginTop: 32,
                    marginBottom: 24,
                  }}
                >
                  Device List
                </h1>
                <div
                  style={{
                    display: "flex",
                    gap: 18,
                    padding: "12px 16px 12px 24px",
                    color: "#c6c6c6",
                    fontSize: "14px",
                    lineHeight: "16px",
                  }}
                >
                  <span
                    style={{
                      flex: "0 1 260px",
                      minWidth: 50,
                      maxWidth: 260,
                    }}
                  >
                    Device ID
                  </span>
                  <span
                    style={{
                      flex: "0 1 260px",
                      minWidth: 50,
                      maxWidth: 260,
                    }}
                  >
                    Site ID (generated)
                  </span>
                  <span
                    style={{
                      flex: "0 1 145px",
                      minWidth: 145,
                      maxWidth: 145,
                    }}
                  >
                    Device Type
                  </span>
                  <span
                    style={{
                      flex: "0 1 110px",
                      minWidth: 50,
                      maxWidth: 110,
                    }}
                  >
                    IP Address
                  </span>
                  <span
                    style={{
                      flex: "0 1 100px",
                      minWidth: 50,
                      maxWidth: 100,
                    }}
                  >
                    Serial Number
                  </span>
                  <span
                    style={{
                      flex: "0 1 104px",
                      minWidth: 50,
                      maxWidth: 110,
                    }}
                  >
                    Make
                  </span>
                  <span
                    style={{
                      flex: "0 1 126px",
                      minWidth: 50,
                      maxWidth: 130,
                    }}
                  >
                    Model
                  </span>
                  <span
                    style={{
                      flex: "0 1 176px",
                      minWidth: 176,
                      maxWidth: 176,
                    }}
                  />
                </div>
                <div
                  style={{
                    overflowY: "auto",
                  }}
                >
                  {currentSite.deviceIds
                    .sort((deviceIdA, deviceIdB) => {
                      const deviceA = dataState.devices[deviceIdA];
                      const deviceB = dataState.devices[deviceIdB];

                      if (
                        deviceA.changedAt === null ||
                        deviceB.changedAt === null
                      ) {
                        return deviceA.changedAt === deviceB.changedAt
                          ? 0
                          : deviceA.changedAt === null
                          ? 1
                          : -1;
                      }
                      return deviceA.changedAt < deviceB.changedAt ? 1 : -1;
                    })
                    .map((deviceId) => {
                      const device = dataState.devices[deviceId];

                      return (
                        <div
                          key={device.id}
                          style={{
                            marginBottom: "12px",
                            padding: "0 16px 0 24px",
                            borderRadius: "8px",
                            backgroundColor: "#1e1e1e",
                            fontSize: "14px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: 18,
                              alignItems: "center",
                              height: 75,
                              paddingTop: 12,
                              paddingBottom: 12,
                            }}
                          >
                            <div
                              style={{
                                flex: "0 1 260px",
                                minWidth: 50,
                                maxWidth: 260,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                              title={device.id}
                            >
                              <span>{device.id}</span>
                            </div>
                            <div
                              style={{
                                flex: "0 1 260px",
                                minWidth: 50,
                                maxWidth: 260,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                              title={device.siteId ?? "-"}
                            >
                              <span>{device.siteId ?? "-"}</span>
                            </div>
                            <div
                              style={{
                                flex: "0 1 145px",
                                minWidth: 145,
                                maxWidth: 145,
                              }}
                              title={getDeviceTypeLabel(device.deviceType)}
                            >
                              <span className="badge">
                                {getDeviceTypeLabel(device.deviceType)}
                              </span>
                            </div>
                            <div
                              style={{
                                flex: "0 1 110px",
                                minWidth: 50,
                                maxWidth: 110,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                              title={device.ipAddress ?? "-"}
                            >
                              <span>{device.ipAddress ?? "-"}</span>
                            </div>
                            <div
                              style={{
                                flex: "0 1 100px",
                                minWidth: 50,
                                maxWidth: 100,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                              title={device.serialNumber ?? "-"}
                            >
                              <span>{device.serialNumber ?? "-"}</span>
                            </div>
                            <div
                              style={{
                                flex: "0 1 104px",
                                minWidth: 50,
                                maxWidth: 110,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                              title={device.make ?? "-"}
                            >
                              <span>{device.make ?? "-"}</span>
                            </div>
                            <div
                              style={{
                                flex: "0 1 126px",
                                minWidth: 50,
                                maxWidth: 130,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                              title={device.model ?? "-"}
                            >
                              <span>{device.model ?? "-"}</span>
                            </div>
                            <div
                              style={{
                                flex: "0 1 176px",
                                display: "flex",
                                justifyContent: "right",
                                minWidth: 176,
                                maxWidth: 176,
                                marginLeft: 10,
                              }}
                            >
                              <Button
                                className="inline"
                                disabled={dataState.loading}
                                onClick={() =>
                                  setOverlay(`editDevice:${device.id}`)
                                }
                              >
                                Edit Device
                              </Button>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: 18,
                              alignItems: "center",
                              height: 51,
                              paddingTop: 2,
                              paddingBottom: 27,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flex: "0 1 240px",
                                minWidth: 172,
                                maxWidth: 240,
                                fontSize: "16px",
                              }}
                            >
                              <span style={{ fontWeight: 700 }}>
                                Device Status
                              </span>
                              <span
                                style={{
                                  marginLeft: 16,
                                  minWidth: 122,
                                  maxWidth: 122,
                                  textAlign: "center",
                                  whiteSpace: "nowrap",
                                  padding: "2px 16px",
                                  borderRadius: 4,
                                  backgroundColor: device.enrolled
                                    ? "#198038"
                                    : "#393939",
                                  color: device.enrolled
                                    ? "#f4f4f4"
                                    : "#000000",
                                }}
                              >
                                {!device.enrolled ? "Not " : ""}
                                Enrolled
                              </span>
                            </div>
                            <div
                              style={{
                                flex: "0 1 102",
                                minWidth: 56,
                                maxWidth: 102,
                                margin: "0 18px",
                              }}
                            >
                              <span style={{ fontWeight: 700 }}>
                                Network Access
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flex: "0 1 685px",
                                minWidth: 139,
                                maxWidth: 685,
                                alignItems: "center",
                                height: 32,
                                margin: "0 96px 0 18px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: 120,
                                }}
                                title={
                                  !device.ipAddress ||
                                  !validateIpAddress(device.ipAddress)
                                    ? "IP Address must be valid in order to enable Ping"
                                    : `Click to ${
                                        device.deviceMetadata &&
                                        JSON.parse(device.deviceMetadata).ping
                                          ? "disable"
                                          : "enable"
                                      } Ping for this Device`
                                }
                              >
                                <label
                                  htmlFor={`${device.id}-ping-enabled`}
                                  style={
                                    dataState.loading ||
                                    !device.ipAddress ||
                                    !validateIpAddress(device.ipAddress)
                                      ? {
                                          marginRight: 8,
                                          color: "#525252",
                                          cursor: "not-allowed",
                                        }
                                      : {
                                          marginRight: 8,
                                          color: "#f4f4f4",
                                          cursor: "pointer",
                                        }
                                  }
                                >
                                  Ping{" "}
                                  {device.deviceMetadata &&
                                  JSON.parse(device.deviceMetadata).ping
                                    ? "Enabled"
                                    : "Disabled"}
                                </label>
                                <Toggle
                                  checked={
                                    device.deviceMetadata &&
                                    JSON.parse(device.deviceMetadata).ping
                                  }
                                  disabled={
                                    dataState.loading ||
                                    !device.ipAddress ||
                                    !validateIpAddress(device.ipAddress)
                                  }
                                  id={`${device.id}-ping-enabled`}
                                  onChange={async (e) => {
                                    await toggleNetworkServiceAccess(
                                      device,
                                      "ping",
                                      !device.deviceMetadata ||
                                        !JSON.parse(device.deviceMetadata).ping
                                    );
                                    // Ensures the toggle is no longer disabled before trying to refocus
                                    setTimeout(() => {
                                      e.target.focus();
                                    }, 0);
                                  }}
                                />
                              </div>
                              {["RSU"].includes(device.deviceType) ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: 136,
                                    marginLeft: 36,
                                  }}
                                  title={
                                    !device.ipAddress ||
                                    !validateIpAddress(device.ipAddress)
                                      ? "IP Address must be valid in order to enable SNMP"
                                      : !device.deviceSecrets ||
                                        !JSON.parse(device.deviceSecrets)
                                          .snmp_username ||
                                        !JSON.parse(device.deviceSecrets)
                                          .snmp_auth_password
                                      ? "SNMP credentials must be valid in order to enable SNMP"
                                      : `Click to ${
                                          device.deviceMetadata &&
                                          JSON.parse(device.deviceMetadata).snmp
                                            ? "disable"
                                            : "enable"
                                        } SNMP for this Device`
                                  }
                                >
                                  <label
                                    htmlFor={`${device.id}-snmp-enabled`}
                                    style={
                                      dataState.loading ||
                                      !device.ipAddress ||
                                      !validateIpAddress(device.ipAddress) ||
                                      !device.deviceSecrets ||
                                      !JSON.parse(device.deviceSecrets)
                                        .snmp_username ||
                                      !JSON.parse(device.deviceSecrets)
                                        .snmp_auth_password
                                        ? {
                                            marginRight: 8,
                                            color: "#525252",
                                            cursor: "not-allowed",
                                          }
                                        : {
                                            marginRight: 8,
                                            color: "#f4f4f4",
                                            cursor: "pointer",
                                          }
                                    }
                                  >
                                    SNMP{" "}
                                    {device.deviceMetadata &&
                                    JSON.parse(device.deviceMetadata).snmp
                                      ? "Enabled"
                                      : "Disabled"}
                                  </label>
                                  <Toggle
                                    checked={
                                      device.deviceMetadata &&
                                      JSON.parse(device.deviceMetadata).snmp
                                    }
                                    disabled={
                                      dataState.loading ||
                                      !device.ipAddress ||
                                      !validateIpAddress(device.ipAddress) ||
                                      !device.deviceSecrets ||
                                      !JSON.parse(device.deviceSecrets)
                                        .snmp_username ||
                                      !JSON.parse(device.deviceSecrets)
                                        .snmp_password
                                    }
                                    id={`${device.id}-snmp-enabled`}
                                    onChange={async (e) => {
                                      await toggleNetworkServiceAccess(
                                        device,
                                        "snmp",
                                        !device.deviceMetadata ||
                                          !JSON.parse(device.deviceMetadata)
                                            .snmp
                                      );
                                      // Ensures the toggle is no longer disabled before trying to refocus
                                      setTimeout(() => {
                                        e.target.focus();
                                      }, 0);
                                    }}
                                  />
                                </div>
                              ) : null}
                              {["RSU"].includes(device.deviceType) ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: 158,
                                  }}
                                  title={
                                    !device.ipAddress ||
                                    !validateIpAddress(device.ipAddress)
                                      ? "IP Address must be valid in order to enable SSH"
                                      : !device.deviceSecrets ||
                                        !JSON.parse(device.deviceSecrets)
                                          .ssh_username ||
                                        !JSON.parse(device.deviceSecrets)
                                          .ssh_password
                                      ? "SSH credentials must be valid in order to enable SSH"
                                      : `Click to ${
                                          device.deviceMetadata &&
                                          JSON.parse(device.deviceMetadata).ssh
                                            ? "disable"
                                            : "enable"
                                        } SSH for this Device`
                                  }
                                >
                                  <label
                                    htmlFor={`${device.id}-ssh-enabled`}
                                    style={
                                      dataState.loading ||
                                      !device.ipAddress ||
                                      !validateIpAddress(device.ipAddress) ||
                                      !device.deviceSecrets ||
                                      !JSON.parse(device.deviceSecrets)
                                        .ssh_username ||
                                      !JSON.parse(device.deviceSecrets)
                                        .ssh_password
                                        ? {
                                            marginRight: 8,
                                            marginLeft: 36,
                                            color: "#525252",
                                            cursor: "not-allowed",
                                          }
                                        : {
                                            marginRight: 8,
                                            marginLeft: 36,
                                            color: "#f4f4f4",
                                            cursor: "pointer",
                                          }
                                    }
                                  >
                                    SSH{" "}
                                    {device.deviceMetadata &&
                                    JSON.parse(device.deviceMetadata).ssh
                                      ? "Enabled"
                                      : "Disabled"}
                                  </label>
                                  <Toggle
                                    checked={
                                      device.deviceMetadata &&
                                      JSON.parse(device.deviceMetadata).ssh
                                    }
                                    disabled={
                                      dataState.loading ||
                                      !device.ipAddress ||
                                      !validateIpAddress(device.ipAddress) ||
                                      !device.deviceSecrets ||
                                      !JSON.parse(device.deviceSecrets)
                                        .ssh_username ||
                                      !JSON.parse(device.deviceSecrets)
                                        .ssh_password
                                    }
                                    id={`${device.id}-ssh-enabled`}
                                    onChange={async (e) => {
                                      await toggleNetworkServiceAccess(
                                        device,
                                        "ssh",
                                        !device.deviceMetadata ||
                                          !JSON.parse(device.deviceMetadata).ssh
                                      );
                                      // Ensures the toggle is no longer disabled before trying to refocus
                                      setTimeout(() => {
                                        e.target.focus();
                                      }, 0);
                                    }}
                                  />
                                </div>
                              ) : null}
                            </div>
                            <div
                              style={{
                                flex: "0 1 176px",
                                display: "flex",
                                justifyContent: "right",
                                minWidth: 176,
                                maxWidth: 176,
                                marginLeft: 10,
                              }}
                            >
                              <Button
                                className="inline"
                                disabled // TODO: Enable once the API works
                                onClick={() =>
                                  setOverlay(`removeDevice:${device.id}`)
                                }
                              >
                                Remove Device
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(100vh - 374px)",
                }}
              >
                <h2 style={{ fontSize: "34px" }}>No enrolled Devices</h2>
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <h2 style={{ fontSize: "34px" }}>Site not found</h2>
        </div>
      )}
    </>
  );
};
export default SiteDetailsView;
