import React from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarProps,
  ToolbarPropsOverrides,
} from "@mui/x-data-grid";

const InteractiveTable = ({
  columns,
  rows,
  toolbar,
}: {
  columns: GridColDef[];
  rows: { id: string | number; [key: string]: React.ReactNode }[];
  toolbar?: React.JSXElementConstructor<
    GridToolbarProps & ToolbarPropsOverrides & { rowCount: number }
  >;
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    }}
  >
    <DataGrid
      columns={columns}
      disableColumnMenu
      disableColumnResize
      hideFooter
      isRowSelectable={() => false}
      rows={rows}
      showColumnVerticalBorder={false}
      slots={{ toolbar } as any}
      slotProps={{
        toolbar: {
          rowCount: rows.length,
        },
      }}
      style={{ border: "none" }}
    />
  </div>
);
export default InteractiveTable;
